.ratings {
  display: grid;
  grid-template-columns: 1fr minmax(0, 115px) min-content;
  grid-auto-rows: 18px;
  align-items: center;
  grid-gap: 10px 15px;
}

.row {
  display: contents;
  color: #847c7c;
  font-size: 10px;
  cursor: pointer;
  grid-row-end: span 1;
  
  &.hover,
  &.selected {
    color: var(--primary-color);
    fill: var(--primary-color);
    --color: var(--primary-color);
    .ratingBar {
      --color: var(--primary-color);
    }
  }
}

.ratingBar {
  align-self: stretch;
  display: flex;
  align-items: center;
  grid-column-start: 2;
  grid-column-end: span 1;

  & > * {
    flex-grow: 1;
  }
}

.rowName {
  text-align: right;
  transition: 200ms;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  grid-column-start: 1;
  grid-column-end: span 1;
  font-size: 12px;
  gap: 2px;

  svg {
    height: 1em;
    width: auto;
    flex-shrink: 0;
  }
}
.star {
  --fill-opacity: 1;
  color: #d3d3d3;
}

.rowCount {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  grid-column-start: 3;
  grid-column-end: span 1;
  min-width: 14px;
}

.pin {
  grid-column-start: -2;
  width: 100%;
  height: 100%;
  svg {
    height: auto;
    width: 90%;
  }

  .pinned & {
    color: var(--primary-color);
    --fill-opacity: 1;
  }
}

.tooltipRow {
  grid-column-start: 1;
  grid-column-end: -1;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.pinnable .tooltipRow {
  grid-column-end: -2;
}

.delightedScore {
  font-size: 8px;
  background: var(--color);
  display: inline-flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 1000em;
  flex-direction: row;
  padding: 0.1875em 0.5em;
  font-weight: bold;

  svg {
    width: auto;
    height: 0.625em;
    display: block;
    --tone1: #fff;
    stroke-width: 0.5em;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .divider {
    display: block;
    margin: 0 0.3125em;
    border-radius: 1000em;
    width: 0.125em;
    height: 0.875em;
    background: rgba(255, 255, 255, 0.3);
  }
}

.rowDgD {
  grid-column-start: -6;
  svg {
    cursor: grab;
  }
}