.section {
  border-top: 1px solid #eee;
  margin: 0 8px;
  display: flex;
  flex-direction: column;
}

.sectionName {
  display: flex;
  flex-direction: row;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 8px 6px;
  letter-spacing: -0.03em;
  justify-content: space-between;
  transition: 200ms;
  cursor: pointer;

  &:hover {
    background: #efefef;
  }

  svg {
    flex-shrink: 0;
    transition: 300ms;
    height: 14px;
    width: auto;
    align-self: center;
  }
}

.opened .sectionName svg {
  transform: rotate(180deg);
}

.sectionRows {
  display: grid;
  grid-template-columns: 40% 1fr;
  grid-template-rows: min-content;
  align-items: stretch;
}

.rowName {
  font-size: 12px;
  font-weight: normal;
  padding: 8px 6px;
  color: #5a5a5a;
}

.rowValue {
  font-size: 12px;
  font-weight: normal;
  padding: 8px 6px;
}