.container {
  display: flex;
  flex-direction: row;
  height: 110px;
  overflow: auto;
  padding: 2px;
  gap: 10px;
}

.image {
  height: 100%;
  width: auto;
  display: block;
  box-shadow: 0 0 2px 1px var(--border-color);
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
  transition: all 0.5s ease-in-out;

  &:hover {
    filter: brightness(50%);
  }

  img {
    height: 100%;
    width: auto;
    display: block;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modalContent {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modalContent img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  display: block;
  margin: 0 auto;
  height: auto;
}

.closeButton {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.previousButton,
.nextButton {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px 10px;
  cursor: pointer;
}

.previousButton, .nextButton  {
  background: transparent;
  color: white;
  border: none;
  font-size: 10em;
  --icon-size: 100px;
}

.previousButton {
  left: 10px;
}

.nextButton {
  right: 10px;
}

.chevronLeft {
  transform: rotate(180deg);
}