.concepts {
display: flex;
flex-direction: column;
}

.ratingBar {
  align-self: stretch;
  display: flex;
  align-items: center;
  grid-column-start: 2;
  grid-column-end: span 1;
  margin: 5px 0;
  cursor: pointer;
}
.ratingBar > * {
  flex-grow: 1;
}

.ratingBar.selected,
.rowName.selected,
.rowCount.selected,
.ratingBar.hovering,
.rowName.hovering,
.rowCount.hovering {
  color: var(--primary-color);
  --tone1: var(--primary-color);
  --color: var(--primary-color) !important;
}
.rowName.selected {
  text-decoration-line: underline;
  text-decoration-color: thistle;
}



.row.hoverable:hover {
  color: var(--primary-color);
  --tone1: var(--primary-color);
  --color: var(--primary-color) !important;
}

.aspectRow {
  display: contents;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  grid-column-start: 2;
  grid-column-end: span 1;
}

.aspectRowButton {
  padding: 3px;
  border-radius: 4px;
  background: #666;
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  background: var(--color);
  color: #fff;
  transition: 200ms;
  cursor: pointer;

  &:hover,
  &.selected {
    background: var(--hover-color);
  }
}

.aspectRowSpacer {
  flex-basis: 0;
  flex-grow: 1;
}

.rowName {
  text-align: right;
  transition: 200ms;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  grid-column-start: 1;
  grid-column-end: span 1;
  height: 18px;
  cursor: pointer;
  color: #847c7c;
  font-size: 11px;
  cursor: pointer;
  --tone1: #d3d3d3;
  margin: 5px 0;

  svg {
    margin-left: 2px;
    height: 1em;
    width: auto;
    &:first-child {
      margin-left: 0;
    }
  }

  .rowIcon {
    margin-left: 0.75em;
    width: auto;
    height: 1em;
    display: block;
    flex-shrink: 0;
  }
}

.rowCount {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  grid-column-start: 3;
  grid-column-end: span 1;
  cursor: pointer;
  color: #847c7c;
  font-size: 10px;
  cursor: pointer;
  --tone1: #d3d3d3;
}

.newRowName {
  text-align: center;
  transition: 200ms;
  grid-column-start: 1;
  grid-column-end: -1;
  color: #847c7c;
  font-size: 10px;
  cursor: pointer;
  border: 1px dashed transparent;
  border-radius: 1000em;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: 200ms;

  &:link,
  &:visited {
    color: #847c7c;
  }
  &:hover {
    border: 1px dashed #847c7c;
  }
}

.tooltipRow {
  grid-column-start: 1;
  grid-column-end: -1;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.tooltipRowFooter {
  opacity: 0.8;
  color: #fbf9f6;
  font-size: 12px;
}

.pin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  grid-column-start: 4;
  grid-column-end: span 1;
  cursor: pointer;
  transition: 200ms;
  color: #aaa;
  box-sizing: border-box;

  svg {
    height: auto;
    width: 100%;
  }

  &:hover,
  .pinned {
    color: var(--primary-color);
  }
}



.row {
  display: contents;
  color: #847c7c;
  font-size: 10px;
  cursor: pointer;
  fill: #d3d3d3;
}
