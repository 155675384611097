.ratings {
  display: grid;
  grid-template-columns: 1fr minmax(0, 115px) min-content;
  grid-auto-rows: 18px;
  align-items: center;
  grid-gap: 10px 15px;
}

.row {
  position: relative;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  gap:10px;
  align-items: center;
  color: #847c7c;
  --tone1: #d3d3d3;


  &.hover,
  &.selected {
    color: var(--primary-color);
    fill: var(--primary-color);
    --color: var(--primary-color);
    .ratingBar {
      --color: var(--primary-color);
    }
  }
}

.ratingBar {
  display: flex;
  align-items: center;
  min-width: 90px;
  & > * {
    flex-grow: 1;
  }
}
.rowName {
  text-align: left;
  color: #847c7c;
  font-size: 11px;
  gap: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  width: 100%;
  // max-width: 0px;

  svg {
    height: 1em;
    width: auto;
    flex-shrink: 0;
  }
}
// .star {
//   --fill-opacity: 1;
//   color: #d3d3d3;
// }

.rowCount {
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  font-size: 12px;
  min-width: 14px;
  width: 100%;
  max-width: 20px;
  
}

.pin {
  width: 10%;
  height: 10%;
  transition: none;
  text-align: left;
  svg {
    height: 1em;
  }

  .pinned & {
    color: var(--primary-color);
    --fill-opacity: 1;
  }
}

.tooltipRow {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
}

// .pinnable .tooltipRow {
//   grid-column-end: -2;
// }

// .delightedScore {
//   font-size: 8px;
//   background: var(--color);
//   display: inline-flex;
//   color: #fff;
//   align-items: center;
//   justify-content: center;
//   border-radius: 1000em;
//   flex-direction: row;
//   padding: 0.1875em 0.5em;
//   font-weight: bold;

//   svg {
//     width: auto;
//     height: 0.625em;
//     display: block;
//     --tone1: #fff;
//     stroke-width: 0.5em;
//     flex-shrink: 0;
//     flex-grow: 0;
//   }

//   .divider {
//     display: block;
//     margin: 0 0.3125em;
//     border-radius: 1000em;
//     width: 0.125em;
//     height: 0.875em;
//     background: rgba(255, 255, 255, 0.3);
//   }
// }

.rowDgD {
  position: relative;
  svg {
    position: absolute;
    z-index: 4;
    cursor: grab;
  }
}

.draggableReview {
  user-select: none;
  position: relative;
  height: 100%;

  &.dragging {
    background: transparent;
  }
}