.variables {
  --border-radius: 8px;
  --icon-size: 20px;
  --font-size: 16px;
  --line-height: calc(1.5 * var(--font-size));
  --input-padding-vertical: 8px;
  --input-padding-horizontal: 12px;
  --icon-padding-vertical: 10px;
  --icon-padding-horizontal: 12px;
  --label-gap: calc(var(--icon-padding-horizontal) * 2 / 3);
  --box-shadow: 0px 1px 2px hsla(220, 43%, 11%, 0.09);
  --background-color: hsl(0, 0%, 100%);
  --border-color: hsl(217, 16%, 84%);
  --icon-color: hsl(0, 0%, 47%);
  --title-color: hsl(217, 34%, 29%);
  --checkbox-color-hsl: 218, 63%, 59%;
  --checkbox-color: hsl(var(--checkbox-color-hsl));
  --error-color: hsl(0, 57%, 46%);

  &.small {
    --border-radius: 5px;
    --icon-size: 16px;
    --font-size: 14px;

    //do not use % here, it messes with things
    --line-height: calc(1.4 * var(--font-size));
    --input-padding-vertical: 5px;
    --icon-padding-vertical: 6px;
    --icon-padding-horizontal: 7px;
  }

  &.borderless {
    --border-color: transparent;

    .label {
      border: none;
    }
  }
}

@mixin errorStyles {
  background-color: #f7e0ba;
  color: #292929;
  position: relative;
  &:after {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 3px);
    border-radius: 5px;
    transform: translateX(-50%);
    z-index: 500000;
    width: max-content;
    background-color: #eef4ff;
    color: rgb(26, 108, 180);
    padding: 2px 6px;
    transition: 0.2s all ease-in-out;
    opacity: 0;
    border: 1px solid hsl(225, 37%, 73%);
    box-shadow: 0px 1px 3px hsla(220, 43%, 11%, 0.1), 0px 1px 2px hsla(220, 43%, 11%, 0.06),
      0px 0px 0px 4px hsla(230, 27%, 57%, 0.1);
    pointer-events: none;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: var(--main-font);
  position: relative;

  &:focus-within {
    --background-color: #ffffee;
  }

  &.hasError {
    --title-color: var(--error-color);
    --border-color: var(--error-color);
    --icon-color: var(--error-color);
  }

  &.hideFormatting {
    :global {
      .ql-toolbar {
        display: none;
      }
      .ql-container.ql-snow {
        border-radius: var(--border-radius);
        border: 1px solid #ededed;
      }
    }
    .downloadDocBtn {
      display: none;
    }
  }
  &.bubbleTheme {
    :global(.quill) {
      box-shadow: none;
    }
    .error {
      padding-left: 10px;
    }
  }
}

.downloadDocBtn {
  position: absolute;
  right: 4px;
  top: 32px;
}

.renderItem {
  display: flex;
  justify-content: space-between;
}

.renderItem .renderItemName.renderItemName {
  text-transform: uppercase;
  color: #aaa;
  font-size: 10px;
  letter-spacing: 0.02em;
  flex-shrink: 0;
  padding-right: 11px;
}

.renderItem .renderItemValue.renderItemValue {
  margin-right: auto;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 320px;
  font-size: 12px;
  font-family: var(--main-font);
  color: rgb(113, 160, 189);
  padding-left: 11px;
}

.renderItem .renderItemImage.renderItemImage {
  width: 40px;
  height: auto;
  max-height: 33px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--title-color);
  padding: 1px calc(var(--border-radius) / 2);
}
.subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: var(--title-color);
}

.error {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: var(--error-color);
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--label-gap);
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
  background: var(--background-color);
  border-radius: var(--border-radius);

  & > :first-child {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }

  & > :last-child {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
}

.input,
.textarea {
  padding: var(--input-padding-vertical) var(--input-padding-horizontal);
  flex-grow: 1;
  outline: 0;
  border: none;
  border-color: transparent;
  font-size: var(--font-size);
  line-height: var(--line-height);
  overflow: hidden;
  resize: none;
  min-height: calc(var(--line-height) + 2 * var(--input-padding-vertical));
  font-family: var(--main-font);
  position: relative;
  background-color: var(--background-color);

  &[type='color'] {
    cursor: pointer;
    appearance: none;
    padding: 0;
    border-radius: calc(var(--border-radius) - 1px);

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    &::-webkit-color-swatch {
      border: none;
    }
  }
  &[type='checkbox'],
  &[type='radio'] {
    display: none;
    & + .icon {
      --stroke-width: 2px;
    }
    &:not(:checked) + .icon svg {
      opacity: 0;
    }
    &:not(:checked) + .icon .circle {
      opacity: 0;
    }
  }
}

.input {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.disabled {
  .input {
    cursor: default;
    background: hsla(0, 0%, 94%, 0.3);
    color: hsl(0, 0%, 63%);
  }
}

.icon {
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: 50%;
  display: block;
  color: var(--icon-color);
  flex-shrink: 0;
  flex-grow: 0;
  margin: var(--icon-padding-vertical) 0 var(--icon-padding-vertical) var(--icon-padding-horizontal);
  transition: 200ms;

  & + .input {
    padding-left: 0;
  }

  svg {
    height: 100%;
    width: 100%;
    display: block;
  }
}

.circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--checkbox-color);
}

.checkbox .icon {
  background: hsla(var(--checkbox-color-hsl), 0.12);
  color: var(--checkbox-color);
  --stroke-width: 2.2;
  padding: 1px;
  border: 1px solid var(--checkbox-color);
  border-radius: 4px;

  svg {
    transition: opacity 200ms;
  }
}
.radio .icon {
  background: hsla(var(--checkbox-color-hsl), 0.12);
  color: var(--checkbox-color);
  --stroke-width: 2.2;
  padding: 3px;
  border: 1px solid var(--checkbox-color);
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    transition: opacity 200ms;
  }
}

.loading .label {
  opacity: 1;
  opacity: 1;
  background: linear-gradient(
    110deg,
    hsl(220, 40%, 98%) 8%,
    hsl(0, 0%, 100%) 18%,
    hsl(220, 40%, 98%) 33%
  );
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}
.loading + .loading .label {
  opacity: 0.6;
}
.loading + .loading + .loading .label {
  opacity: 0.2;
}

.select {
  max-width: 100%;
  // width: calc(
  //   5px + var(--input-padding-horizontal) * 2 + var(--icon-size) + var(--max-label-length)
  // );

  width: calc(5px + var(--icon-size) + var(--max-label-length));
  position: relative;
  .chevron {
    transition: 0.2s ease-out all;
    color: hsl(0, 0%, 78%);
  }
  input {
    width: 100%;
  }
  &.focusedSelect {
    .chevron {
      transform: rotate(180deg);
    }
  }
}

.dropdown {
  display: flex;
  flex-direction: column;
  background: hsl(0, 0%, 100%);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 9px 2px hsla(0, 0%, 73%, 0.678);
  z-index: 100;
  border-radius: var(--border-radius);
  overflow: hidden;
  
  .dropdownOptions {
    max-height: 240px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}
.dropdownInput {
  border-bottom: 1px solid var(--border-color);
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  overflow: hidden;
}

.options {
  max-height: 200px;
  overflow-y: auto;
  background: hsl(0, 0%, 100%);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 9px 2px hsla(0, 0%, 73%, 0.678);
  z-index: 10;
  border-radius: var(--border-radius);
}
.option {
  border: none;
  transition: all 140ms ease-out;
  font-style: normal;
  font-weight: 500;
  font-variation-settings: 'wght' 500;
  background-color: var(--background-color);
  color: var(--title-color);
  font-size: var(--font-size);
  line-height: var(--line-height);
  padding: var(--input-padding-vertical) var(--input-padding-horizontal);
  cursor: pointer;

  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;

  &.withIcon {
    padding: var(--input-padding-vertical) var(--input-padding-horizontal)
      var(--input-padding-vertical) var(--icon-padding-horizontal);
  }

  &.currentOption {
    background: hsla(var(--checkbox-color-hsl), 0.3);
  }
  &.selectedOption {
    background-color: var(--checkbox-color);
    color: hsl(0, 0%, 100%);
  }
}
.optionIcon {
  width: 16px;
  height: 16px;
  display: block;
  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.container :global {
  .quill {
    flex-grow: 1;
    outline: 0;
    border: 0;
    overflow: visible;
    resize: none;
    min-height: calc(var(--line-height) + 2 * var(--input-padding-vertical));
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    background: var(--background-color);

    &:focus-within {
      .ql-container,
      .ql-toolbar {
        border-color: hsl(208deg 45.06% 78.15%);
        background-color: rgb(246, 251, 255);
      }
    }
  }
  .ql-toolbar {
    &.ql-snow {
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
      background-color: rgb(237, 241, 249);
    }
    .ql-stroke {
      stroke: var(--icon-color);
    }
  }
  .ql-container {
    font-size: var(--font-size);
    font-family: var(--main-font);

    &.ql-snow {
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }
  .ql-editor {
    padding: var(--input-padding-vertical) var(--input-padding-horizontal);
    line-height: 1.6em;
    font-size: 14px;
    overflow: visible;
    &:before {
      /* this is how quill does placeholder*/
      left: var(--input-padding-horizontal);
      font-style: normal;
    }

    p,
    ul li,
    ol li {
      font-size: var(--font-size);
      font-family: var(--main-font);
    }
    h1 {
      font-size: 2em;
      line-height: 1.5em;
    }
    h2 {
      font-size: 1.6em;
      line-height: 1.5em;
    }
    h3 {
      font-size: 1.5em;
      line-height: 1.5em;
    }
    h4 {
      font-size: 1.3em;
      line-height: 1.5em;
    }
    h5 {
      font-size: 1.1em;
      line-height: 1.5em;
    }
    h6 {
      font-size: 1em;
      line-height: 1.5em;
    }
    ul,
    ol {
      padding-left: 0.2em;
    }
  }
  .ql-mention-list-container {
    width: 270px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
    z-index: 9001;
    overflow: auto;
    max-height: 200px;
  }
  .mention-img {
    &[src=''] {
      display: none;
    }
  }
  .ql-mention-loading {
    line-height: 26px;
    vertical-align: middle;
    font-size: 16px;
  }

  .ql-mention-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .ql-mention-list-item {
    cursor: pointer;
    line-height: 26px;
    font-size: 16px;
    // padding: 0 20px;
    vertical-align: middle;
  }

  .ql-mention-list-item.disabled {
    cursor: auto;
  }

  .ql-mention-list-item.selected {
    background-color: #d3e1eb;
    text-decoration: none;
  }
}
